import p5_img from "./product_5.png"
import p6_img from "./product_6.png"
import p7_img from "./product_7.png"
import p8_img from "./product_8.png"

let data_product = [
    {
        id:5,
        name:"Emerald Couch",
        image:p5_img,
        new_price:250000,
        old_price:300000,
    },

    {
        id:6,
        name:"Light-Grey Sofa",
        image:p6_img,
        new_price:100000,
        old_price:120000,
    },

    {
        id:7,
        name:"Mushroom Couch",
        image:p7_img,
        new_price:300000,
        old_price:350000,
    },

    {
        id:8,
        name:"Yellow Couch",
        image:p8_img,
        new_price:350000,
        old_price:380000,
    },

    
];

export default data_product;