import React from 'react'
import './Footer.css'
import logo_footer from '../Assets/logo_footer.png'
import instagram from '../Assets/instagram.png'
import whatsapp from '../Assets/whatsapp.png'
import x from '../Assets/x.png'
import { Link } from 'react-router-dom'
import facebook from '../Assets/facebook.png' 

const Footer = () => {
  return (
    <div className='footer'>
      <div className="logo_footer">
        <img src={logo_footer} alt="" />
      </div>
      <ul className="footer-links">
        <li><Link to='/'>Home</Link></li>
        <li><Link to='/aboutUs'>About Us</Link></li>
        <li><Link to='/sofa'>Sofa</Link></li>
        <li><Link to='/ottoman'>Ottoman</Link></li>
        <li><Link to='/contactUs'>Contact</Link></li>
      </ul>
      <div className="footer-social-icons">
        <div className="footer-icons-container">
            <img src={instagram} alt="" />
        </div>
        <div className="footer-icons-container">
            <img src={facebook} alt="" />
        </div>
        <div className="footer-icons-container">
            <img src={x} alt="" />
        </div>
        <div className="footer-icons-container">
            <img src={whatsapp} alt="" />
        </div>
      </div>
       <div className="footer-copyright">
            <hr />
            <p>Copyright 2024 - All rights reserved</p>
       </div>
      

    </div>
  )
}

export default Footer
