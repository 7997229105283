import p1_img from "./product_1.png"
import p2_img from "./product_2.png"
import p3_img from "./product_3.png"
import p4_img from "./product_4.png"
import p5_img from "./product_5.png"
import p6_img from "./product_6.png"
import p7_img from "./product_7.png"
import p9_img from "./product_9.png"
import p10_img from "./product_10.png"
import p11_img from "./product_11.png"
import p12_img from "./product_12.png"


let all_product = [
    {
        id:1,
        name:"Black Leather",
        category:"sofa",
        image:p1_img,
        new_price:250000,
        old_price:300000,
    },

    {
        id:2,
        name:"Blue",
        category: "sofa",
        image: p2_img,
        new_price: 100000,
        old_price: 120000,
    },

    {
        id: 3,
        name: "Brown",
        category: "ottoman",
        image: p3_img,
        new_price: 300000,
        old_price: 350000,
    },

    {
        id: 4,
        name: "Wine",
        category: "sofa",
        image: p4_img,
        new_price: 350000,
        old_price: 380000,
    },

    {
        id: 5,
        name: "Green",
        category: "sofa",
        image: p5_img,
        new_price: 320000,
        old_price: 350000,
    },

    {
        id: 6,
        name: "Ash",
        category: "ottoman",
        image: p6_img,
        new_price: 300000,
        old_price: 350000,
    },

    {
        id: 7,
        name: "Champaign",
        category: "sofa",
        image: p7_img,
        new_price: 300000,
        old_price: 350000,
    },

    {
        id: 8,
        name: "Yellow",
        category: "ottoman",
        image: p9_img,
        new_price: 300000,
        old_price: 350000,
    },

    {
        id: 9,
        name: "Munchroom",
        category: "sofa",
        image: p9_img,
        new_price: 300000,
        old_price: 350000,
    },

    {
        id: 10,
        name: "Navy-Blue",
        category: "ottoman",
        image: p10_img,
        new_price: 400000,
        old_price: 450000,
    },

    {
        id: 11,
        name: "Pattern",
        category: "ottoman",
        image: p11_img,
        new_price: 200000,
        old_price: 250000,
    },

    {
        id: 12,
        name: "Ashley",
        category: "ottoman",
        image: p12_img,
        new_price: 150000,
        old_price: 170000,
    },
]

export default all_product;