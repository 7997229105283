import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ShopCategory from "./Pages/ShopCategory";
import ContactUs from "./Pages/ContactUs";
import Cart from "./Pages/Cart";
import LoginSignup from "./Pages/LoginSignup";
import Product from "./Pages/Product";
import Footer from "./Components/Footer/Footer";
import sofa_banner from "./Components/Assets/sofa_banner.png";
import ottoman_banner from "./Components/Assets/ottoman_banner.png";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/sofa" element={<ShopCategory banner={sofa_banner} category="sofa" />} />
          <Route path="/ottoman" element={<ShopCategory banner={ottoman_banner} category="ottoman" />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/product" element={<Product />}>
            <Route path=":productId" element={<Product />} />
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<LoginSignup />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
