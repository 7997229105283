import React, { useState } from 'react' 
import './Navbar.css'
import logo_header from '../Assets/logo_header.png'
import cart from '../Assets/cart.png'
import { Link } from 'react-router-dom'
import menu_icon from '../Assets/hamburger.png'

const Navbar = () => {

     
     const [mobileMenu, setMobileMenu] = useState(false);
     const toggleMenu = () =>{
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
     }

    return (
        <nav className="container">          
                <img src={logo_header} alt="" className="logo" />          
            <ul className={mobileMenu ? '' : 'hide-mobile-menu'}>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/aboutUs'>About Us</Link></li>
                <li><Link to='/sofa'>Sofa</Link></li>
                <li><Link to='/ottoman'>Ottoman</Link></li>
                <li><Link to='/contactUs'>Contact</Link></li>             
            </ul>
           
            <img src={menu_icon} alt="" className='menu-icon' onClick={toggleMenu} />   
        </nav>
    )
}

export default Navbar