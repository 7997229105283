import React from 'react'
import './CSS/ContactUs.css'

const ContactUs = () => {
  return (
    <div className='contactus'>
      <h1>CONTACT US</h1>
      <hr />
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet lectus proin nibh nisl condimentum id venenatis a. Lobortis mattis aliquam faucibus purus in massa tempor nec feugiat. Nibh praesent tristique magna sit. Euismod lacinia at quis risus sed vulputate odio ut. 
      </p>
        
    </div>
  )
}

export default ContactUs
