import p1_img from "./product_1.png"
import p2_img from "./product_2.png"
import p3_img from "./product_3.png"
import p4_img from "./product_4.png"

let data_product = [
    {
        id:1,
        name:"Black Leather",
        category:"Sofa",
        image:p1_img,
        new_price:250000,
        old_price:300000,
    },

    {
        id:2,
        name:"Blue",
        category: "Sofa",
        image: p2_img,
        new_price: 100000,
        old_price: 120000,
    },

    {
        id: 3,
        name: "Brown",
        category: "Ottoman",
        image: p3_img,
        new_price: 300000,
        old_price: 350000,
    },

    {
        id: 4,
        name: "Wine",
        category: "Sofa",
        image: p4_img,
        new_price: 350000,
        old_price: 380000,
    },

    
];

export default data_product;