import React from 'react'
import './CSS/AboutUs.css'

const AboutUs = () => {
  return (
    <div className='aboutus'>
      <h1>ABOUT US</h1>
      <hr />
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eleifend quam adipiscing vitae proin sagittis nisl. At erat pellentesque adipiscing commodo elit at imperdiet. Praesent elementum facilisis leo vel fringilla est ullamcorper eget. Est lorem ipsum dolor sit amet. Non curabitur gravida arcu ac. Eget nullam non nisi est. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat. Ut sem nulla pharetra diam sit amet. Dignissim diam quis enim lobortis scelerisque fermentum. Dapibus ultrices in iaculis nunc sed augue lacus viverra. Purus viverra accumsan in nisl nisi scelerisque. Cursus turpis massa tincidunt dui ut ornare. Faucibus turpis in eu mi bibendum neque egestas. Pretium fusce id velit ut tortor pretium viverra suspendisse. Urna neque viverra justo nec ultrices dui sapien eget mi.
      </p>

      <p>
      Et leo duis ut diam quam. Placerat duis ultricies lacus sed turpis tincidunt id. Semper risus in hendrerit gravida rutrum quisque non tellus. Sed lectus vestibulum mattis ullamcorper velit sed. Posuere lorem ipsum dolor sit amet. Semper risus in hendrerit gravida rutrum quisque non. Dignissim suspendisse in est ante in nibh. Sed viverra ipsum nunc aliquet bibendum enim facilisis. Faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis. Suspendisse interdum consectetur libero id faucibus nisl. In est ante in nibh mauris. Nulla malesuada pellentesque elit eget gravida cum. Eget arcu dictum varius duis at consectetur lorem donec. Amet est placerat in egestas erat. Convallis aenean et tortor at risus. Pharetra diam sit amet nisl suscipit adipiscing bibendum est. Viverra orci sagittis eu volutpat odio facilisis.
      </p>
        
    </div>
  )
}

export default AboutUs
