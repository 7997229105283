import React from 'react'
import './ProductDisplay.css'
import star from "../Assets/star.png";
import star_dull from "../Assets/star_dull.png";


const ProductDisplay = (props) => {
    const {product} = props;
  return (
    <div className='productdisplay'>
        <div className="productdisplay-left">
           <div className="productdisplay-img-list">
                <img src={product.image} alt="" />
                <img src={product.image} alt="" />
                <img src={product.image} alt="" />z
                <img src={product.image} alt="" />
            </div> 
            <div className="productdisplay-img">
            <img className='productdisplay-main-img' src={product.image} alt="" />
            </div>
        </div>
        <div className="productdisplay-right">
           <h1>{product.name}</h1> 
           <div className="productdisplay-right-star">
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star_dull} alt="" />
                <p>(100)</p>
           </div>
           <div className="productdisplay-right-prices">
                <div className="productdisplay-right-price-old">#{product.old_price}</div>
                <div className="productdisplay-right-price-new">#{product.new_price}</div>
           </div>
           <div className="productdisplay-right-description">
            Afordable luxury
           </div>
           
        </div>

    </div>
  )
}

export default ProductDisplay